import lazySizes from '../1-vendors/lazysizes.js';
import { $$ } from '../2-helpers/query-selector';

function init() {
  const saveData =
    'connection' in navigator && navigator.connection.saveData === true;

  // Load lower resolution images if saveData
  if (saveData) {
    const images = $$('.lazyload');
    for (const image of images) image.dataset.optimumx = 1;
  }

  // Remove bg to avoid artefacts
  document.addEventListener('lazyloaded', e => {
    const parent = e.target.parentNode;

    if (parent.classList.contains('o-figure__wrapper')) {
      parent.classList.add('o-figure__wrapper--transparent');
    }
  });

  lazySizes.init();
}

init();
