import { $, $$ } from '../2-helpers/query-selector';

function closeVitas(activeButton) {
  var vitas = $$('.js-vita');
  var vitaNames = $$('.js-vita-name');
  var buttons = $$('.vitabutton');

  for (var vita of vitas) {
    vita.classList.add('vita-hide');
  }

  for (var vitaName of vitaNames) {
    vitaName.classList.add('vita-hide');
  }

  for (var button of buttons) {
    if (button !== activeButton) {
      $('span', button).textContent = button.dataset.showtext;
      button.classList.remove('vitabutton-toggle');
    }
  }
}

function vita() {
  var buttons = $$('.vitabutton');

  for (const button of buttons) {
    button.addEventListener('click', function() {
      closeVitas(this);

      const vitaClass = this.dataset.vita;
      const vita = $('.js-vita' + '.' + vitaClass);
      const vitaName = $('.js-vita-name' + '.' + vitaClass);

      if (this.classList.contains('vitabutton-toggle')) {
        $('span', this).textContent = this.dataset.showtext;

        vita.classList.add('vita-hide');
        vitaName.classList.add('vita-hide');
        this.classList.remove('vitabutton-toggle');
      } else {
        $('span', this).textContent = this.dataset.hidetext;

        vita.classList.remove('vita-hide');
        vitaName.classList.remove('vita-hide');
        this.classList.add('vitabutton-toggle');
      }
    });
  }
}

function init() {
  vita();
}

init();
