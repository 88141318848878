import { $, $$ } from '../2-helpers/query-selector';

function nav() {
  var menu = $('.nav');
  var openButton = $('.js-nav-toggle');
  var navIcon = $('.js-nav-icon');

  openButton.addEventListener('click', function() {
    menu.classList.toggle('menu-visible');
    navIcon.classList.toggle('o-nav-icon--active');
  });
}

function navOptions() {
  var menu = $('.nav');
  var menuOptions = $$('.nav a');
  var navIcon = $('.js-nav-icon');

  for (var link of menuOptions) {
    link.addEventListener('click', function() {
      menu.classList.remove('menu-visible');
      navIcon.classList.remove('o-nav-icon--active');
    });
  }
}

function init() {
  nav();
  navOptions();
}

init();
